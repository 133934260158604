import { graphql } from 'gatsby'
import { Container } from '@chakra-ui/react'
import { TextPage } from '@components/TextPage'

const PrivacyPage = ({ data }) => {
  return (
    <Container maxW="container.lg" py={24}>
      <TextPage html={data.privacyJson.content.childMarkdownRemark.html} />
    </Container>
  )
}

export default PrivacyPage

export const query = graphql`
  query PrivacyQuery {
    privacyJson {
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
